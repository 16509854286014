/* eslint-disable no-console */
import { useEffect } from 'react';
import isMobileApp, { isIosDevices } from '@root/src/helpers/userAgent';
import { generateLoyalticUrl } from '@root/src/helpers/loyalticPushConfig';
import { setCookies, getCookies } from '@helper_cookies';

const useLoyalticPushNotification = (props) => {
    const {
        isLogin,
        customer,
    } = props;

    const loyalticFcmCookies = getCookies('loyaltic_fcm');

    useEffect(() => {
        if (isLogin
            && isLogin === 1
            && customer?.customer?.phonenumber
            && isMobileApp() === 'app'
            && window?.reactNativeApp !== loyalticFcmCookies
        ) {
            const fetchLoyaltic = async () => {
                try {
                    const response2 = await fetch(
                        generateLoyalticUrl(),
                        {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                data: {
                                    mobile: customer?.customer?.phonenumber || '',
                                    fcm_token: window?.reactNativeApp || '',
                                    platform: isIosDevices() ? 'ios' : 'android',
                                },
                            }),
                        },
                    );
                    const updateCustomer = await response2.json();
                    const cdid = updateCustomer?.cdid;
                    if (cdid) {
                        setCookies('loyaltic_fcm', window?.reactNativeApp || '');
                    }
                } catch (error) {
                    console.log('error', error);
                }
            };
            fetchLoyaltic();
        }
    }, [customer, isLogin, loyalticFcmCookies]);
};

export default useLoyalticPushNotification;
