const { loyalticPushnotificationUrl } = require('@root/swift.config');

const generateLoyalticUrl = () => {
    const appEnv = typeof window !== 'undefined' ? window.APP_ENV : process.env.APP_ENV;
    const loyalticUrl = `${loyalticPushnotificationUrl[appEnv] || loyalticPushnotificationUrl.prod}`;
    return loyalticUrl;
};

module.exports = {
    generateLoyalticUrl,
};
