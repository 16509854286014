import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    footerContainer: {
        overflow: 'hidden',
        [theme.breakpoints.up('sm')]: {
            marginTop: 0,
        },
    },
    contentWrap: {
        '&.fullWidth': {
            width: '100% !important',
        },
        [theme.breakpoints.up('sm')]: {
            width: '95%',
        },
        [theme.breakpoints.up('xs')]: {
            width: '90%',
        },
    },
    marginTopMain: {
        '@media (min-width: 768px)': {
            marginTop: '170px !important',
        },
    },
    fullHeightMobile: {
        marginTop: '-18px',
    },
}));

export default useStyles;
